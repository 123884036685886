<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Our approach to pricing</h1>
      <p>
        We are not an extractive business but instead try that everything we do is regenerative in order to
        improve the land we operate from.
      </p>
      <p>
        Our customers appreciate the value of a product or service by fully understanding where it comes from and how it
        is made. The more we all know, the better we can make decisions.
      </p>
      <p>
        There is no set price for any product or service. As a customer you can move a pricing slider and set your own
        price. We believe in your judgement. There is a suggested price and you can lower or increase the price you are
        willing to pay. The more you increase the price the more we are able to invest into the regenerative part of our
        operation. If you lower the price, we understand that for now that's what you can afford.
      </p>


      <YouTubeLink />

    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import YouTubeLink from '@/components/YouTubeLink.vue'

export default {
  name: "PriceSlider",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
